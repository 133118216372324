import React, { Component } from 'react';
import logo from './logo.svg';

class Serpentine extends Component {
  render() {
    return <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Serpentine project overview coming soon.
      </p>
    </header>;
  }

}

export default Serpentine;
